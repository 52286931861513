<script setup>
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/stores/app'
import { useUserStore } from '~/stores/user'
import { usePageStore } from '~/stores/page'

const appStore = useAppStore()
const { isMobile, drawer, drawerRail } = storeToRefs(appStore)

const userStore = useUserStore()
const { user } = storeToRefs(userStore)

const pageStore = usePageStore()
const { userPages } = storeToRefs(pageStore)

function initialize() {
  //  console.log(`AppNavDrawer.initialize(): userPages = ${JSON.stringify(userPages.value)}`)
}

onMounted(() => {
  initialize()
})
</script>

<template>
  <v-navigation-drawer v-model="drawer" width="210" expand-on-hover :rail="drawerRail" :location="isMobile ? 'bottom' : undefined" class="pt-11 drawer-bg">
    <v-list>
      <v-list-item v-for="(page, i) in userPages" :key="i" variant="text" :value="page" :to="page.to">
        <template #prepend>
          <v-icon :icon="page.icon"></v-icon>
        </template>
        <v-list-item-title>{{ page.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
