<script setup>
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/stores/app'

const appStore = useAppStore()
const { helpDrawer, isMobile } = storeToRefs(appStore)

const isExpanded = ref(false)

function initialize() {
  // console.log(`Help.initialize()`)
}

initialize()
</script>

<template>
  <v-navigation-drawer v-model="helpDrawer" temporary width="400" location="right" :class="!isMobile && isExpanded ? 'expanded' : ''">
    <v-card class="pt-15" flat>
      <v-card-title class="d-flex align-center">
        <div>Help:</div>
        <v-spacer></v-spacer>
        <v-tooltip :text="!isMobile && isExpanded ? `Unexpand` : 'Expand'" location="top">
         <template #activator="{ props }">
            <v-btn v-if="!isMobile" variant="plain" density="compact" :icon="isExpanded ? 'mdi-arrow-expand-right' : 'mdi-arrow-expand-left'" @click="isExpanded = !isExpanded" v-bind="props"></v-btn>
         </template>
       </v-tooltip>

      </v-card-title>
      <v-divider></v-divider>

      <HelpDocs></HelpDocs>

      <v-card-actions class="d-flex justify-space-between">
        <v-btn variant="text" @click.stop="helpDrawer = !helpDrawer"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-navigation-drawer>
</template>

<style scoped lang="scss">
.expanded {
  width: calc(100vw - 100px) !important;
}
</style>
