<script setup>
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/stores/app'
import { useUserStore } from '~/stores/user'
import { useAuthStore } from '~/stores/auth'

const appStore = useAppStore()
const { settingsDrawer } = storeToRefs(appStore)

const authStore = useAuthStore()
const { logout } = authStore
const { isPending } = storeToRefs(authStore)

const userStore = useUserStore()

const config = useRuntimeConfig()
const version = computed(() => config.public.APP_VERSION)

function initialize() {
  // console.log(`Settings.initialize()`)
}

function closeSettings() {
  settingsDrawer.value = false
}

function logoutUser() {
  console.log(`logoutUser()`)
  closeSettings()
  logout()
}

initialize()
</script>

<template>
  <v-navigation-drawer v-model="settingsDrawer" temporary location="right">
    <v-card class="pt-15" flat>
      <v-card-title>Site Settings:</v-card-title>
      <v-divider></v-divider>

      <v-list>
        <v-list-item>
      IEC TBS: v{{version}}
          <v-list-item-action> </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-card-actions class="d-flex justify-space-between">
        <v-btn variant="flat" color="secondary" @click="logoutUser"> Logout </v-btn>
        <v-btn variant="text" @click="closeSettings"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-navigation-drawer>
</template>
