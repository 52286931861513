<script setup>
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/stores/app'
import { useUserStore } from '~/stores/user'
import { useNotificationStore } from '~~/stores/notification'

const appStore = useAppStore()
const { isMobile, drawer, siteName, siteNameShort } = storeToRefs(appStore)
const { toggleRightDrawers, toggleSearchDrawer } = appStore

const userStore = useUserStore()
const { user, unreadUserNotifications } = storeToRefs(userStore)

const notificationStore = useNotificationStore()
const { unreadNotifications } = storeToRefs(notificationStore)

const mainClass = computed(() => ({
  'main-left': !isMobile.value && drawer.value,
}))

function initialize() {
  console.log(`MainLayout.initialize()`)
}

onBeforeMount(() => {
  drawer.value = null
})
onMounted(() => {
  initialize()
})
</script>

<template>
  <v-layout full-height class="app-layout light-bg">
    <v-app-bar :height="isMobile ? '65px' : '75px'" class="appbar">
      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <v-app-bar-nav-icon color="white" v-bind="props" class="mr-3" @click.stop="drawer = !drawer" />
        </template>
        Menu
      </v-tooltip>

      <GlobalAppLogo :appbar="true" />

      <!-- Site Title -->
      <v-btn variant="plain" size="large" class="ml-3 px-0 toolbar_btn breadcrumbs-btn text-white justify-start" to="/" nuxt>{{ isMobile ? siteNameShort : siteName }}</v-btn>

      <GlobalBreadCrumbs />

      <v-spacer />

       <!-- My Profile menu -->
      <GlobalAppProfileMenu v-if="user" />

      <!-- Notifications Menu btn -->
      <v-tooltip text="Notifications" location="bottom">
        <template #activator="{ props }">
          <v-btn v-if="unreadUserNotifications > 0" icon color="white" v-bind="props" @click.stop="navigateTo({ path: '/notifications/' })">
            <v-badge :content="unreadUserNotifications" color="error">
              <v-icon>mdi-bell</v-icon>
            </v-badge>
          </v-btn>

          <v-btn v-else icon color="white" v-bind="props" @click.stop="toggleRightDrawers('notifications')">
            <v-icon>mdi-bell</v-icon>
          </v-btn>
        </template>
      </v-tooltip>

      <!-- Help Menu btn-->
      <v-tooltip text="Help" location="bottom">
        <template #activator="{ props }">
          <v-btn icon color="white" v-bind="props" @click.stop="toggleRightDrawers('help')">
            <v-icon>mdi-help-circle</v-icon>
          </v-btn>
        </template>
      </v-tooltip>

      <!-- Settings Menu btn -->
      <v-tooltip text="Settings" location="bottom">
        <template #activator="{ props }">
          <v-btn icon color="white" v-bind="props" @click.stop="toggleRightDrawers('settings')">
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </v-app-bar>

    <!-- Navigation Menu -->
    <GlobalAppNavDrawer />

    <!-- Search Drawer -->
    <!-- <GlobalAppSearchDrawer /> -->

    <!-- Help Drawer -->
    <GlobalAppHelpDrawer />

    <!-- Settings Drawer -->
    <GlobalAppSettingsDrawer />

    <v-main class="pt-12 fill-height light-bg" :class="mainClass">
      <slot />
    </v-main>

    <GlobalAppFooter />

    <GlobalAppNotification />
  </v-layout>
</template>

<style lang="scss">
/* Tabs */
:deep(.v-tabs--icons-and-text > .v-tabs-bar) {
  height: auto;
}
:deep(.v-tabs--icons-and-text > .v-tabs-bar .v-tab) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: row-reverse;
}
</style>
